import React, { FunctionComponent, useState, useCallback } from 'react';
import { ContactPageStyle } from './ContactPageStyle';
import Layout from '../../layouts/Layout';
import Button from '../../components/Form/Button';
import TextArea from '../../components/Form/TextArea';
import ImageIcon from '../../components/ImageIcon';
import { Form } from 'react-final-form';
import FormField from '../../components/Form/FormField';
import { css } from '@emotion/core';
import api from '../../services/api';
import { FadeLoader } from 'react-spinners';
import { navigate } from '@reach/router';
import ConfirmModal from '../../components/ConfirmModal';
import { Helmet } from 'react-helmet';

const emailRegrex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface ContactMessage {
  name: string,
  email: string,
  phoneNumber: string,
  message: string
}

interface ConfirmModal {
  className?: string;
  header?: string;
  body?: string;
  buttonText?: string;
  showing?: boolean;
  callback?: () => void;
}

const Contact: FunctionComponent<{}> = () => {
  const [initialValues, setInitialValues] = useState<ContactMessage>({
    name: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const goToHomePage = () => {
    navigate('/');
  }

  const [confirmation, setConfirmation] = useState<ConfirmModal>({
    header: 'Message sent!',
    body: 'Thanks for sending your message. We will be in touch with a response soon.',
    buttonText: 'Return to homepage',
    showing: false,
    callback: goToHomePage
  });

  const onSubmitForm = (values: object) => {
    setIsLoading(true);

    api({
      method: 'post',
      url: `/customer-service/contact`,
      data: values
    })
      .then(response => {
        setIsLoading(false);
        setConfirmation({ ...confirmation, showing: true });

        if ('success' in response.data && response.data.success === true) {
          setInitialValues({
            name: '',
            email: '',
            phoneNumber: '',
            message: ''
          });
          navigate('/contact');
        } else {
          setConfirmation({
            ...confirmation,
            header: 'Message sent!',
            body: 'Thanks for sending your message. We will be in touch with a response soon.',
            buttonText: 'Return to homepage',
            showing: true
          });
        }
      })
      .catch(_ => {
        setIsLoading(false);
        setConfirmation({
          ...confirmation,
          header: 'Warning',
          body: 'An error occured when sending your message. Please try again.',
          buttonText: 'OK',
          showing: true,
          callback: () => { }
        });
      });
  }

  return (
    <Layout isContactPage>
      <ContactPageStyle>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Contact</title>
        </Helmet>
        <div
          css={css`
          position: fixed;
          top: 0;
          left: 0;
          z-index: 2;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.3);
          display: ${confirmation.showing ? 'flex' : 'none'};
          justify-content: center;
          align-items: center;
          .spinner {
            transform: translateY(-4em);
          }
        `}>
          <ConfirmModal
            header={confirmation.header}
            body={confirmation.body}
            buttonText={confirmation.buttonText}
            callback={confirmation.callback} />
        </div>
        <div
          css={css`
          position: fixed;
          top: 0;
          left: 0;
          z-index: 2;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.3);
          display: ${isLoading ? 'flex' : 'none'};
          justify-content: center;
          align-items: center;
          .spinner {
            transform: translateY(-4em);
          }
        `}
        >
          <div className="spinner">
            <FadeLoader color="#fff" />
          </div>
        </div>
        <div className="text--container">
          <p className="text--title">Lets Chat</p>
        </div>
        <div className="body--container">
          <div className="intro--container">
            <p className="text--sub">Media Inquiries</p>
            <p className="text--content">
              For quotes, interviews or other media requests, please email <span className="text--warning">press@travelpix.io</span>{' '}
            </p>
            <p className="text--content">
              Unfortunately, the press office doesn't have access to account information and can't help with customer inquiries. Please use
              the form on the right to contact Customer Services.
            </p>
          </div>

          <Form
            css={css`
             margin-top: 1rem;
            `}
            onSubmit={onSubmitForm}
            initialValues={initialValues}
            validate={values => {
              const errors = {};
              if (!values.name) {
                errors.name = 'This field is required';
              }
              if (!values.email || !emailRegrex.test(values.email)) {
                errors.email = 'Please input a valid email e.g. name@example.com';
              }
              if (!values.phoneNumber || values.phoneNumber.length < 5) {
                errors.phoneNumber = 'Please input a valid phone number';
              }

              if (!values.message) {
                errors.message = 'This field is required';
              }
              return errors;
            }}
            render={({ handleSubmit }) => {
              return (
                <form
                  className="form--container"
                  onSubmit={handleSubmit}>

                  <p className="text--sub">Send us a quick message</p>

                  <FormField
                    type="text"
                    name="name"
                    label="Your name"
                    offsetTopMoved="7px"
                    className="input--control"
                  />

                  <FormField
                    type="email"
                    name="email"
                    label="Your email (name@example.com)"
                    offsetTopMoved="7px"
                    className="input--control"
                  />

                  <FormField
                    type="email"
                    name="phoneNumber"
                    label="Phone number"
                    offsetTopMoved="7px"
                    className="input--control"
                  />

                  <FormField
                    component={TextArea}
                    type="text"
                    name="message"
                    label="Message"
                    offsetTopMoved="7px"
                    className="textarea--control"
                    rows={8}
                    maxLength={5000}
                  />

                  <div>
                    <Button
                      label="Send message"
                      icon={<ImageIcon src="/icon/right_arrow.svg" className="font--svg" />}
                      className="button--control"
                      type="submit"
                    />
                  </div>
                </form>
              )
            }} />
        </div>
      </ContactPageStyle>
    </Layout>
  );
};

export default Contact;
