import styled from '@emotion/styled';

const ContactPageStyle = styled.div`
  justify-content: start;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 60rem;
  margin-top: 6.5rem;

  color: #a5a7ad;
  .text--warning {
    font-size: 13px;
    line-height: 20px;
    color: #fbca00;
    font-family: RubikMedium;
  }

  .text--sub {
    color: #1d2332;
    font-size: 20px;
    line-height: 24px;
    font-family: RubikMedium;
    width: 100%;
    margin-top: 0;
  }

  .text--container {
    display: flex;
    justify-content: flex-start;
    padding: 3em 0;
    margin-left: -12%;

    @media screen and (min-width: 768px) {
      max-width: 752px;
      width: 100%;
      align-self: center;
    }

    @media screen and (max-width: 768px) {
      padding-left: 30px;
    }

    .text--title {
      margin: 0;
      font-size: 60px;
      font-family: RubikMedium;
      line-height: 64px;
      color: #fbca00;

      @media screen and (max-width: 425px) {
        font-size: 30px;
      }
    }
    @media screen and (max-width: 840px) {
      padding: 3em 2.5em;
      padding-bottom: 0;
    }
  }
  .body--container {
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
    justify-content: space-between;
    align-items: flex-start;
    align-self: center;
    max-width: 752px;
    width: 100%;
    margin-left: -12%;

    @media screen and (max-width: 768px) {
      justify-content: center;
    }

    .intro--container {
      flex: 5;
      max-width: 260px;
      p.text--content {
        line-height: 20px;
        font-size: 13px;
      }
    }
    .form--container {
      flex: 5;
      display: flex;
      flex-direction: column;
      max-width: 410px;

      .input--control {
        margin: 0;

        input {
          font-size: 14px;
          font-family: RubikMedium;
        }
      }

      .button--control {
        width: 200px;
        padding: 1em 1.53em 1em 1.75em;
        font-family: RubikMedium;
        font-size: 15px;
        line-height: 18px;
        float: right;

        .font--svg {
          margin: 0 0 0 22px;
          width: 15px;
          height: 10px;
        }
        @media screen and (max-width: 425px) {
          width: 200px;
          margin-left: auto;
        }
      }
      .text-area {
        padding: 0 20px 10px 20px;
        margin-top: 24px;
      }
    }
  }

  @media only screen and (max-width: 840px) {
    .body--container {
      margin-left: 0%;
      flex-direction: column-reverse;
      padding: 2.5rem;

      .intro--container,
      .form--container {
        width: 100%;
        padding: 0;
      }
      .intro--container {
        p.text--content {
          max-width: 100%;
        }
      }
    }

    .text--container {
      margin-left: 0%;
    }
  }

  @media only screen and (max-width: 726px) {
    .body--container {
      margin-left: 0%;
      padding: 0 1.875rem 8rem 1.875rem;
    }
    .text--container {
      margin-left: 0%;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
`;

export { ContactPageStyle };
